import React from 'react'

import Hero from '../../components/Hero'
import Highlight from '../../components/Highlight'
import Story from '../../components/Story'
import OurStory from '../../components/OurStory'
import Services from '../../components/Services'
import Feature from '../../components/Feature'
import Reviews from '../../components/Reviews'
import { homeHero, storyCommitment } from './Data'

import 'bootstrap/dist/css/bootstrap.min.css'

function Home() {
    return (
        <div>
            <Hero {...homeHero}/>
            <Highlight />
            <OurStory />
            <Services />
            <Story {...storyCommitment}/>
            <Feature />
            <Reviews />
        </div>
    )
}

export default Home
