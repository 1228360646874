import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Hero from '../../components/Hero'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Services.css'
import { serviceHero, serviceRealEstate, servicePropertyManagement, servicePropertySales } from './Data'

function Services() {
    return (
        <div>
            <Hero {...serviceHero}/>
            <div className="service-container section-container">
                <div className="section-heading">
                    Our Services
                </div>
                <div>
                    <Container>
                        <Row className="service-row bg-gray">
                            <Col md={6} className="service-img">
                                <img src={serviceRealEstate.img} alt={serviceRealEstate.imgAlt}/>
                            </Col>
                            <Col md={6}>
                                <div className="service-heading">
                                    {serviceRealEstate.heading}
                                </div>
                                <div className="service-desc">
                                    {serviceRealEstate.desc}
                                </div>
                            </Col>
                        </Row>
                        <Row className="service-row bg-gray">
                            <Col md={{span:6,order:'last'}} className="service-img">
                                <img src={servicePropertyManagement.img} alt={servicePropertyManagement.imgAlt}/>
                            </Col>
                            <Col md={6}>
                                <div className="service-heading">
                                    {servicePropertyManagement.heading}
                                </div>
                                <div className="service-desc">
                                    {servicePropertyManagement.desc}
                                </div>
                            </Col>
                        </Row>
                        <Row className="service-row bg-gray">
                            <Col md={6} className="service-img">
                                <img src={servicePropertySales.img} alt={servicePropertySales.imgAlt}/>
                            </Col>
                            <Col md={6}>
                                <div className="service-heading">
                                    {servicePropertySales.heading}
                                </div>
                                <div className="service-desc">
                                    {servicePropertySales.desc}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Services
