import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ContactInfo from './ContactInfo'
import ContactForm from './ContactForm'

import 'bootstrap/dist/css/bootstrap.min.css'
import './ContactUs.css'

function ContactUs() {
    return (
        <div className="section-container contact-us-container">
            <Container>
                <Row>
                    <Col>
                        <div className="contact-us-label">
                            Contact Us
                        </div>
                        <div className="contact-us-heading">
                            Hotline: <span>+639260642982</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={7} className="contact-us-form">
                        <ContactForm />
                    </Col>
                    <Col lg={{span:4, offset:1}} xs={{order:'last'}} className="contactus-contact">
                        <ContactInfo lightBackground={true} hideButton={true}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs
