export const serviceHero = {
    img: "/images/hero-service.jpg",
    fullHeight: true,
    centerAlign: false,
    headingText: "INVESTMENT IS THE NEW NOW",
    headingTextBold: "INVEST WITH METROSUMMIT",
    headingColor: "#FFFFFF",
    hideDesc: true,
    hideCta: true
}

export const serviceRealEstate = {
    img: "/images/service-realestate.jpg",
    imgAlt: "Real Estate Services",
    heading: "Real Estate Services",
    desc: "Our licensed and duly trained real estate agents are well equipped to give you the proper assistance from assessing your family's need, to finding the right property and administering the transfer of titles and the turning over of units.",
}

export const servicePropertyManagement = {
    img: "/images/service-propertymanagement.jpg",
    imgAlt: "Property Management",
    heading: "Property Management",
    desc: "Our services do not stop upon unit turnover, but extend further to the management of your property. From handling administrative tasks and paper works, and conducting marketing, evaluation, rent collections, and repair and maintenance, count on our team of specialized personnel to give you full assistance with your needs."
}

export const servicePropertySales = {
    img: "images/service-propertysales.jpg",
    imgAlt: "Property Sales and Marketing Management",
    heading: "Property Sales and Marketing Management",
    desc: "We specialize in the sales and the marketing management of the most sought-after state-of-the-art residential properties in Metro Manila."
}