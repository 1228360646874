import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Hero.css'

function Hero({
    img,
    fullHeight,
    centerAlign,
    headingSize,
    headingTextRaw,
    headingText,
    headingTextBold,
    headingColor,
    hideDesc,
    descText,
    descColor,
    hideCta,
    ctaLabel,
    ctaColor,
    ctaLink
}) {
    return (
        <div className={ fullHeight ? "hero-container full-height" : "hero-container"} style={{
            backgroundImage: `url(${img})`
        }}>
            <Container>
                <Row>
                    <Col md={{span:10,offset:1}} className={centerAlign ? "hero-center" : "" }>
                        <div className={"hero-heading " + headingSize} style={{ color: headingColor }}>
                            { ReactHtmlParser (headingTextRaw) } 
                        </div>
                        <div className={"hero-heading " + headingSize} style={{ color: headingColor }}>
                            {headingText}
                        </div>
                        <div className={"hero-heading bold " + headingSize} style={{ color: headingColor }}>
                            {headingTextBold}
                        </div>
                        <div className={hideDesc ? "hidden" : "hero-desc"} style={{ color: descColor }}>
                            {descText}
                        </div>
                        <Link to={ctaLink}>
                            <button className={hideCta ? "hidden" : `hero-cta ${ctaColor}`}>
                                {ctaLabel}
                            </button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
