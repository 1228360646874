import React from 'react'

import { BiEnvelope, BiMap } from 'react-icons/bi'
import { FaFacebookF, FaTwitter, FaInstagram , FaYoutube, FaTiktok } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import './ContactInfo.css'

function ContactInfo({
    lightBackground,
    hideButton
}) {
    return (
        <div className={ lightBackground ? 'contact-info-container light-background' : 'contact-info-container' }>
            <div className="contact-info-socials"> 
                <Link to={{ pathname: "https://www.facebook.com/metrosummitrealty/" }} target="_blank">
                    <FaFacebookF/>
                </Link>
                <Link to={{ pathname: "https://www.instagram.com/metrosummit.realty/" }} target="_blank">
                    <FaInstagram/>
                </Link>
                <Link to={{ pathname: "https://www.youtube.com/channel/UCfr9Ruver9BxrvZ5m0V_z-A" }} target="_blank">
                    <FaYoutube/>
                </Link>
                <Link to={{ pathname: "https://twitter.com/metrosummitreal" }} target="_blank">
                    <FaTwitter/>
                </Link>
                <Link to={{ pathname: "https://www.tiktok.com/@metrosummitrealty" }} target="_blank">
                    <FaTiktok/>
                </Link>
            </div>
            <div className="contact-info-details">
                <p><BiEnvelope/>Email Address</p>
                <p>metrosummitrealty2020@gmail.com</p>
            </div>
            <div className={ hideButton ? "contact-info-details no-margin" : "contact-info-details" }>
                <p><BiMap/>Location</p>
                <p>129 Congressional Ave., Brgy. Bahay Toro, Quezon City</p>
                <p>Metro Manila, Philippines</p>
            </div>
            <div className={ hideButton ? "hidden" : "contact-info-cta"}>
                <Link to="/contact">
                    <button className="btn-color-highlight">
                        Contact Us
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default ContactInfo
