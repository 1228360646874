import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Story.css'

function Story({
    img,
    label,
    heading,
    desc
}) {
    return (
        <div className="section-container story-container" style={{
            backgroundImage: `url(${img})`
        }}>
            <Container>
                <Row>
                    <Col>
                        <div className="story-label">
                            {label}
                        </div>
                        <div className="story-heading">
                            {heading}
                        </div>
                        <div className="story-desc">
                            {desc}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Story
