import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Carousel from 'react-bootstrap/Carousel'
import Hero from '../../components/Hero'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Company.css'
import { companyHeroCarousel1, companyHeroCarousel2 , companyHeroCarousel3 } from './Data'
import { companyAbout, companyMission , companyVision } from './Data'

import imgMission from '../../assets/images/company-mission.jpg'
import imgVision from '../../assets/images/company-vision.jpg'

function Company() {
    return (
        <div>
            <Carousel className="hero-carousel">
                <Carousel.Item interval={10000}>
                    <Hero {...companyHeroCarousel1} />
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <Hero {...companyHeroCarousel2} />
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <Hero {...companyHeroCarousel3} />
                </Carousel.Item>
            </Carousel>
            <div className="section-container">
                <Container>
                    <Row>
                        <div className="section-heading">
                            {companyAbout.heading}
                        </div>
                    </Row>
                    <Row>
                        <Col lg={{span:8,offset:2}}>
                            <div className="aboutus-desc">
                                <p>{companyAbout.desc1}</p>
                                <p>{companyAbout.desc2}</p>
                                <p>{companyAbout.desc3}</p>
                                <p>{companyAbout.desc4}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section-container bg-gray missionvision-container">
                <Container>
                    <Row>
                        <Col sm={{span:8,offset:2}} md={{span:6,offset:0}} xl={{span:5,offset:1}}>
                            <img src={imgMission} alt={companyMission.heading}/>
                        </Col>
                        <Col sm={{span:8,offset:2}} md={{span:6,offset:0}} xl={{span:5}}>
                            <div className="section-label">
                                {companyMission.label}
                            </div>
                            <div className="missionvision-heading">
                                {companyMission.heading}
                            </div>
                            <div className="missionvision-desc">
                                <p>{companyMission.desc}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{span:8,offset:2}} md={{span:6,offset:0,order:'last'}} xl={{span:5}}>
                            <img src={imgVision} alt={companyVision.heading}/>
                        </Col>
                        <Col sm={{span:8,offset:2}}md={{span:6,offset:0}} xl={{span:5,offset:1}}>
                            <div className="section-label">
                                {companyVision.label}
                            </div>
                            <div className="missionvision-heading">
                                {companyVision.heading}
                            </div>
                            <div className="missionvision-desc">
                                <p>{companyVision.desc}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Company
