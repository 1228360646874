import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import diamond from "../assets/images/diamond.png"
import honesty from "../assets/images/honesty.png"
import key from "../assets/images/key.png"
import management from "../assets/images/project-management.png"

import 'bootstrap/dist/css/bootstrap.min.css'
import './Highlight.css'

function Highlight() {
    return (
        <div className="section-container highlight-container">
            <Container>
                <Row>
                    <Col lg={6} className="highlight-group">
                        <div>
                            <img src={honesty} className="highlight-icon" alt="Reliable and Trustworthy Service"/>
                        </div>
                        <div>
                            <p className="highlight-heading">Reliable and Trustworthy Service</p>
                            <p className="highlight-desc">We commit to providing customers with an unparalleled level of dedicated service from our trained and experienced professionals in the field of sales, property management, and real estate</p>
                        </div>
                    </Col>
                    <Col lg={6} className="highlight-group">
                        <div>
                            <img src={diamond} className="highlight-icon" alt="Luxurious and Breathable Space"/>
                        </div>
                        <div>
                            <p className="highlight-heading">Luxurious and Breathable Space</p>
                            <p className="highlight-desc">It is Metrosummit's goal to bring back that sense of finding a comfortable home designed with luxury for families in Metro Manila.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="highlight-group">
                        <div>
                            <img src={key} className="highlight-icon" alt="Comfort and Safety"/>
                        </div>
                        <div>
                            <p className="highlight-heading">Comfort and Safety</p>
                            <p className="highlight-desc">We commit to helping Filipino families find houses and subdivisions that provide convenience for each member's safety</p>
                        </div>
                    </Col>
                    <Col lg={6} className="highlight-group">
                        <div>
                            <img src={management} className="highlight-icon" alt="State-of-the-art Home Technology"/>
                        </div>
                        <div>
                            <p className="highlight-heading">State-of-the-art Home Technology</p>
                            <p className="highlight-desc">Metrosummit partners with only the best property developers meeting current trends, and having foresight into client's needs</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Highlight
