import React from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Reviews.css'

import reviewsData from "../pages/Home/Reviews.json";

function Reviews() {
    return (
        <div className="section-container bg-gray">
            <Container>
                {/* <Row>
                    <div className="reviews-heading">
                            What Our Customers Have To Say
                    </div>
                </Row> */}
                <Row>
                    <Col md={{span:10,offset:1}}>
                        <Carousel className="reviews-carousel">
                            {
                                reviewsData.reviews.map((review) => {
                                    return (
                                        <Carousel.Item interval={10000}>
                                            <p className="review-comment">{review.comment}</p>
                                            <p className="review-author">{review.author}</p>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Reviews
