import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Story from './Story'
import ContactInfo from './ContactInfo'
import { storyOurStory } from '../pages/Home/Data.js'
import bgourstory from '../assets/images/story-ourstory.jpg'
import bank from '../assets/images/bank.png'
import home from '../assets/images/home.png'
import employees from '../assets/images/employees.png'

import 'bootstrap/dist/css/bootstrap.min.css'
import './OurStory.css'

function OurStory() {
    return (
        <div className="section-container ourstory-container" style={{
            backgroundImage: `url(${bgourstory})`
        }}>
            <Container>
                <Row>
                    <Col lg={7} className="story-section">
                        <Story {...storyOurStory}/>
                    </Col>
                    <Col sm={5} lg={{span:4, offset:1}} xs={{order:'last'}} className="ourstory-contact">
                        <ContactInfo lightBackground={false} hideButton={false}/>
                    </Col>
                    <Col sm={7} lg={{span:12,order:'last'}} className="ourstory-card-list">
                        <div className="ourstory-section-heading">Why People Chose Us?</div>
                        <Row>
                            <Col lg={4}>
                                <div className="ourstory-section-card">
                                    <img src={employees} alt="100+ Property Manager and Marketing Staff"/>
                                    <div className="ourstory-section-desc">
                                        <p><b>100+</b></p>
                                        <p>Property Manager and Marketing Staff</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="ourstory-section-card">
                                    <img src={home} alt="712+ Total Number of Units Participated"/>
                                    <div className="ourstory-section-desc">
                                        <p><b>712+</b></p>
                                        <p>Total Number of Units Participated</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="ourstory-section-card">
                                    <img src={bank} alt="100% Bank Accredited"/>
                                    <div className="ourstory-section-desc">
                                        <p><b>100%</b></p>
                                        <p>Bank Accredited</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurStory
