import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css'

import NavigationBar from './components/NavigationBar';
import Home from './pages/Home/Home';
import Company from './pages/Company/Company';
import Services from './pages/Services/Services';
import Projects from './pages/Project/Projects';
import SingleProject from './pages/Project/SingleProject';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/company" exact component={Company} />
          <Route path="/services" exact component={Services} />
          <Route path="/listings" exact component={Projects} />
          <Route path="/listings/:project" exact component={SingleProject} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
