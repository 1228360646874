export const projectHero = {
    img: "/images/hero-projects-short.jpg",
    fullHeight: false,
    centerAlign: false,
    headingTextBold: "Featured Listings",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const singleProjectHero = {
    img: "/images/hero-contact-short.jpg",
    fullHeight: false,
    centerAlign: true,
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const filterListings = [ 
    "All", 
    "Congressional", 
    "Manila", 
    "New Manila", 
    "Quezon Avenue", 
    "San Juan", 
    "Scout Area", 
    "Sta. Mesa Heights" ]

export const projectListings = [
    {
        image: "/images/listings/listing-alabama-0.jpg",
        label: "Alabama",
        location: "New Manila"
    },
    {
        image: "/images/listings/listing-averilla-0.jpg",
        label: "Averilla",
        location: "San Juan"
    },
    {
        image: "/images/listings/listing-benitez-0.jpg",
        label: "Benitez",
        location: "San Juan"
    },
    {
        image: "/images/listings/listing-cuenco-0.jpg",
        label: "Cuenco",
        location: "Sta. Mesa Heights"
    },
    {
        image: "/images/listings/listing-donasotera-0.jpg",
        label: "Doña Sotera",
        location: "Congressional"
    },
    {
        image: "/images/listings/listing-edsamunoz-0.jpg",
        label: "EDSA - Muñoz",
        location: "Congressional"
    },
    {
        image: "/images/listings/listing-kaingin-0.jpg",
        label: "Kaingin",
        location: "Sta. Mesa Heights"
    },
    {
        image: "/images/listings/listing-mariposa-0.jpg",
        label: "Mariposa",
        location: "San Juan"
    },
    {
        image: "/images/listings/listing-martelino-0.jpg",
        label: "Martelino",
        location: "Quezon Avenue"
    },
    {
        image: "/images/listings/listing-mindanaoavenue-0.jpg",
        label: "Mindanao Avenue",
        location: "Congressional"
    },
    {
        image: "/images/listings/listing-recto-0.jpg",
        label: "Recto",
        location: "Manila"
    },
    {
        image: "/images/listings/listing-road14-0.jpg",
        label: "Road 14",
        location: "Congressional"
    },
    {
        image: "/images/listings/listing-scoutrallos-0.jpg",
        label: "Scout Rallos",
        location: "Scout Area"
    },
    {
        image: "/images/listings/listing-tomasmorato-0.jpg",
        label: "Tomas Morato",
        location: "Scout Area"
    },
    {
        image: "/images/listings/listing-wilson-0.jpg",
        label: "Wilson",
        location: "San Juan"
    }
]