export const homeHero = {
    img: "/images/hero-home.jpg",
    fullHeight: true,
    centerAlign: false,
    headingText: "INVESTMENT IS THE NEW NOW",
    headingTextBold: "INVEST WITH METROSUMMIT",
    headingColor: "#D7D96F",
    hideDesc: false,
    descText: "The company is composed of a team of industry experts\nall driven by the vision of turning every Filipino family's dream home into a reality.",
    descColor: "#FFFFFF",
    hideCta: false,
    ctaLabel: "Call Us Today",
    ctaColor: "btn-color-highlight",
    ctaLink: "/contact"
}

export const storyOurStory = {
    img: "",
    label: "Our Story",
    heading: "Improve the value of life",
    desc: "Through its many years of experience and amount of quality homes provided for Filipino families, Metrosummit stands firmly on top as one of the country's leading and most highly sought after property consultancy firms in Metro Manila. Spread across the most strategic locations in Sta. Mesa Heights, New Manila, Quezon City, San Juan, and Manila. Metrosummit takes pride in finding homes specially designed for the modern Filipino family."
}

export const storyCommitment = {
    img: "/images/story-ourcommitment.jpg",
    label: "Our Commitment",
    heading: "To provide housing option made to last and designed to improve our homeowner's way of living.",
    desc: "We strive to give convenience, enjoyment, and contentment to Filipinos by giving them a home to bring comfort and relief amid the metropolitan's bustling and crowded landscape. We show our unwavering dedication by adhering to these three principles across every home we sell, and every Filipino we provide a home to."
}

export const featuredProjectListings = [
    {
        image: "/images/listings/listing-alabama-0.jpg",
        label: "Alabama",
        location: "New Manila"
    },
    {
        image: "/images/listings/listing-averilla-0.jpg",
        label: "Averilla",
        location: "San Juan"
    },
    {
        image: "/images/listings/listing-mariposa-0.jpg",
        label: "Mariposa",
        location: "San Juan"
    },
    {
        image: "/images/listings/listing-wilson-0.jpg",
        label: "Wilson",
        location: "San Juan"
    }
]