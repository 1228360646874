import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'

import bg from '../assets/images/bg-footer.jpg'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Footer.css'

import { BiLogIn, BiEnvelope, BiMap, BiPhone,  } from 'react-icons/bi'
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'

function Footer() {
    return (
        <>
            <div className="section-container footer-container" style={{
                backgroundImage: `url(${bg})`
            }}>
                <Container>
                    <Row>
                        <Col lg={{span:6, offset:1}}>
                            <div>
                                <p className="footer-comp-heading">Metrosummit Realty and Construction Corporation</p>
                            </div>
                            <div>
                                <p className="footer-comp-desc">Metrosummit Realty and Construction Corporation is engaged in the property management, 
                                after sales service, property loan assistance, and marketing aspects of prime real estate residential 
                                properties in Metro Manila. It was formed and regulated in the Securities and Exchange Commission on October 19, 2006
                                </p>
                            </div>
                            <div className="footer-comp-contact">
                                <p><BiEnvelope/> metrosummitrealty2020@gmail.com</p>
                                <p><BiPhone/> +639260642982</p>
                                <p><BiMap/> Metro Manila, Philippines</p>
                            </div>
                        </Col>
                        <Col lg={{span: 3, offset: 1 }} >
                            <Row>
                                <Col sm={6} lg={12}>
                                    <div>
                                        <p className="footer-heading">Broker's Portal</p>
                                    </div>
                                    <div className="footer-cta">
                                        <Link to={{ pathname: "https://brokers.metrosummitrealty.com/"}} target="_blank" >
                                            <button className="btn-color-highlight">
                                                <p>Log In</p>
                                                <BiLogIn/>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={6} lg={12}>
                                    <div>
                                        <p className="footer-heading">Social Media</p>
                                    </div>
                                    <div className="footer-social">
                                        <Link to={{ pathname: "https://www.facebook.com/metrosummitrealty/" }} target="_blank">
                                            <FaFacebookF/>
                                        </Link>
                                        <Link to={{ pathname: "https://www.instagram.com/metrosummit.realty/" }} target="_blank">
                                            <FaInstagram/>
                                        </Link>
                                        <Link to={{ pathname: "https://www.youtube.com/channel/UCfr9Ruver9BxrvZ5m0V_z-A" }} target="_blank">
                                            <FaYoutube/>
                                        </Link>
                                        <Link to={{ pathname: "https://twitter.com/metrosummitreal" }} target="_blank">
                                            <FaTwitter/>
                                        </Link>
                                        <Link to={{ pathname: "https://www.tiktok.com/@metrosummitrealty" }} target="_blank">
                                            <FaTiktok/>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-rights">
                MetrosummitRealty.com | All Rights Reserved 2020
            </div>
        </>
    )
}

export default Footer
