import React from 'react'

import Hero from '../../components/Hero'

import 'bootstrap/dist/css/bootstrap.min.css'
import { contactHero } from './Data'
import ContactUs from '../../components/ContactUs'

function Contact() {
    return (
        <div>
            <Hero {...contactHero} />
            <ContactUs />
        </div>
    )
}

export default Contact
