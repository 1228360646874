import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ServiceCards from './ServiceCards'
import { serviceRealEstate, servicePropertyManagement, servicePropertySales } from "../pages/Services/Data.js"

import 'bootstrap/dist/css/bootstrap.min.css'
import './Services.css'

function Services() {
    return (
        <div className="section-container services-container">
            <Container>
                <Row>
                    <Col>
                        <div className="section-heading">
                            Our Services
                        </div>
                    </Col>
                </Row>
                <ServiceCards {...serviceRealEstate} />
                <ServiceCards {...servicePropertyManagement} />
                <ServiceCards {...servicePropertySales} />
            </Container>
        </div>
    )
}

export default Services
