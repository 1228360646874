export const companyHeroCarousel1 = {
    img: "/images/herocarousel-company-1.jpg",
    fullHeight: true,
    centerAlign: false,
    headingText: "We build the",
    headingTextBold: "Value of Life",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const companyHeroCarousel2 = {
    img: "/images/herocarousel-company-2.jpg",
    fullHeight: true,
    centerAlign: false,
    headingText: "We aspire to",
    headingTextBold: "make dream\nhomes a reality",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const companyHeroCarousel3 = {
    img: "/images/herocarousel-company-3.jpg",
    fullHeight: true,
    centerAlign: false,
    headingSize: "size-small",
    headingTextRaw: "<b>Top of the Line</b> Amenities,\n<b>Smart Home</b> Technology,\nand <b>Energy Saving</b> Feature",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const companyAbout = {
    label: "",
    heading: "ABOUT US",
    desc1: "Metrosummit Realty and Construction Corporation is engaged in the property management, after sales service, property loan assistance, and marketing aspect of prime real estate residential properties in Metro Manila. It was formed and regulated in the Securities and Exchange Commission on October 19, 2006.",
    desc2: "At Metrosummit, we aspire to make dream homes a reality for Filipino families. We are firmly committed to show top of the line housing options designed on durability, adaptability, and innovation. Spread across the most strategic locations in Quezon City, San Juan, and Manila, Metrosummit takes pride in finding homes specially designed for the modern Filipino family.",
    desc3: "To date, Metrosummit has sold a vast number of townhouses, condominiums, and other housing projects in Metro Manila.",
    desc4: "Metrosummit is composed of a team of industry experts all driven by the vision of turning every Filipino family's dream home into reality."
}

export const companyMission = {
    label: "Our Commitment",
    heading: "Mission",
    desc: "To foster nurturing homes and communities fit for modern Filipino family living in Metro Manila. To develop spaces that are enriching peaceful, and safe; and give Filipino families convenience and relaxation at the comfort of their own home in the heart of Metro Manila."
}

export const companyVision = {
    label: "Our Statement",
    heading: "Vision",
    desc: "Metrosummit Realty and Construction Corporation envision itself to be one of the leaders and models in the growth and development of the real estate industry in the Philippines."
}