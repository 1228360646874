import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './NavigationBar.css'

import logo from '../assets/images/logo-msm.png'
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaTiktok, FaBars, FaTimes } from 'react-icons/fa'

function NavigationBar({act}) {

    const currentLocation = useLocation().pathname;

    const [showMenu, setShow] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(window.innerWidth);
    const toggleMobileMenu = () => {
        setShow(!showMenu)
    }

    const closeMobileMenu = () => {
        setShow(false)
    }

    const windowResize = () => {
        if (window.innerWidth <= 990) {
            setMobileMenu(true);
            setShow(false)
        } else {
            setMobileMenu(false);
            setShow(false)
        }
    }

    useEffect(() => {
        windowResize();
    }, [])

    window.addEventListener('resize', windowResize);

    return (
        <>
            <nav className="navbar-msm">
                <div className="navbar-container">
                    <div>
                        <Link to="/" className="logo">
                            <img src={logo} alt="Metrosummit Realty"/>
                        </Link>
                        <div className="navbar-social">
                            <Link to={{ pathname: "https://www.facebook.com/metrosummitrealty/" }} target="_blank">
                                <FaFacebookF/>
                            </Link>
                            <Link to={{ pathname: "https://www.instagram.com/metrosummit.realty/" }} target="_blank">
                                <FaInstagram/>
                            </Link>
                            <Link to={{ pathname: "https://www.youtube.com/channel/UCfr9Ruver9BxrvZ5m0V_z-A" }} target="_blank">
                                <FaYoutube/>
                            </Link>
                            <Link to={{ pathname: "https://twitter.com/metrosummitreal" }} target="_blank">
                                <FaTwitter/>
                            </Link>
                            <Link to={{ pathname: "https://www.tiktok.com/@metrosummitrealty" }} target="_blank">
                                <FaTiktok/>
                            </Link>
                        </div>
                    </div>
                    <div className={mobileMenu ? "navbar-links mobile" : "navbar-links desktop"}>
                        <Link to="/" className={currentLocation === "/" ? "active" : ""}>Home</Link>
                        <Link to="/company" className={currentLocation === "/company" ? "active" : ""}>Company</Link>
                        <Link to="/services" className={currentLocation === "/services" ? "active" : ""}>Services</Link>
                        <Link to="/listings" className={currentLocation === "/listings" ? "active" : ""}>Listings</Link>
                        <Link to="/contact" className={currentLocation === "/contact" ? "active" : ""}>Contact</Link>
                        <Link to={{ pathname: "https://brokers.metrosummitrealty.com/"}} target="_blank">Broker's Portal</Link>
                    </div>

                    <div className={mobileMenu ? "navbar-menu-icon mobile" : "navbar-menu-icon desktop"}> 
                        {
                            showMenu ? <FaTimes size={"1.6em"} onClick={toggleMobileMenu} /> : <FaBars size={"1.6em"} onClick={toggleMobileMenu}/>
                        }
                    </div>

                </div>
                <div className={showMenu ? "nav-menu active" : "nav-menu"}>
                        <Link to="/" className={currentLocation === "/" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Home</Link>                
                        <Link to="/company" className={currentLocation === "/company" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Company</Link>
                        <Link to="/services" className={currentLocation === "/services" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Services</Link>
                        <Link to="/listings" className={currentLocation === "/listings" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Listings</Link>
                        <Link to="/contact" className={currentLocation === "/contact" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Contact</Link>
                        <Link to={{ pathname: "https://brokers.metrosummitrealty.com/"}} target="_blank" className="nav-menu-links" onClick={closeMobileMenu} >Broker's Portal</Link>
                    </div>
            </nav>  
        </>
    )
}

export default NavigationBar
