import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './ServiceCards.css'
import { Link } from 'react-router-dom'

function ServiceCards({
    img,
    imgAlt,
    heading,
    desc
}) {
    return (
        <Row className="service-card">
            <Col sm={10} md={6} lg={4}>
                <div className="service-card-img">
                    <img src={img} alt={imgAlt}/>
                </div>
            </Col>
            <Col sm={10} md={6} lg={8}>
                <div className="service-card-details">
                    <p className="service-card-heading">{heading}</p>
                    <p className="service-card-desc">{desc}</p>
                    <Link to="/services">
                        <button className="service-card-cta btn-color-highlight bordered">
                            Learn More
                        </button>
                    </Link>
                </div>
            </Col>
        </Row>
    )
}

export default ServiceCards
